"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/*
    MOAT is a popular library used to identify "invalid traffic" and improve the important IVT metric for ads
    This code delays the initialisation of the ads for up to 1 second(by default) waiting for MOAT to load and
    flag the request. If moat fails to do that for the set timeout the ads are initialised anyway.
    As a prerequisite the moat header script used within FT needs to be included in your page. At the time of this commit
    this looks as follows
    <script id='moat' src='https://z.moatads.com/financialtimesprebidheader859796398452/moatheader.js'></script>

*/
function waitForMoatPageData(timeout) {
    return new Promise((resolve, reject) => {
        const checkMoatInterval = setInterval(() => {
            if (isMoatDataAvailable()) {
                window.moatPrebidApi.setMoatTargetingForAllSlots();
                clearInterval(checkMoatInterval);
                clearTimeout(timeoutId);
                resolve(null);
            }
        }, 50);
        const timeoutId = setTimeout(() => {
            clearInterval(checkMoatInterval);
            reject(new Error('Timeout error'));
        }, timeout);
    });
}
exports.default = waitForMoatPageData;
function isMoatDataAvailable() {
    return window.moatPrebidApi &&
        typeof window.moatPrebidApi.pageDataAvailable === "function" &&
        window.moatPrebidApi.pageDataAvailable();
}
